<template>
  <div id="mixandmatchpricing">
    <v-data-table
      :headers="header"
      :items="sizeData"
      :options.sync="options"
      :items-per-page="options.itemsPerPage"
      :loading="loading"
      :server-items-length="itemsLength"
      :height="530"
      class="elevation-1 mb-5"
      hide-default-footer
      @update:page="optionsUpdated()"
      @update:sort-by="optionsUpdated()"
      @update:sort-desc="optionsUpdated()">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <span class="capitalize">
              Sizes
            </span>
          </v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical />
          <v-spacer />
          <v-dialog
            v-model="dialog"
            max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on">
                New Item
              </v-btn>
            </template>
            <v-card>
              <validation-observer
                ref="sizeForm"
                tag="form"
                @submit.prevent="save()">
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col :cols="12">
                        <gw-combobox
                          v-model="formData.type"
                          :items="sizeData"
                          :readonly="modalLoading"
                          :return-object="false"
                          hide-selected
                          label="Type"
                          item-text="type"
                          item-value="type" />
                      </v-col>
                      <v-col
                        v-for="(size, index) in formData.size"
                        :key="`size-${index}`"
                        :cols="12">
                        <v-row>
                          <v-col
                            class="col-input-size"
                            :cols="6">
                            <v-text-field
                              v-model.number="size.id"
                              type="number"
                              :rules="textFieldRules"
                              label="ID"
                              :readonly="modalLoading"
                              :disabled="disableInputSize(size.action)"
                              @input="inputSizeId($event, index)" />
                          </v-col>
                          <v-col
                            class="col-input-size"
                            :cols="6">
                            <v-text-field
                              v-model="size.name"
                              :rules="textFieldRules"
                              label="Size"
                              :readonly="modalLoading"
                              :disabled="disableInputSize(size.action)"
                              @input="inputSize($event, index)" />
                            <v-btn
                              v-if="disableInputSize(size.action)"
                              class="size-form-bin"
                              icon
                              small
                              @click="cancelRemoveInputSize(index)">
                              <v-icon>
                                mdi-close-circle-outline
                              </v-icon>
                            </v-btn>
                            <gw-icon-hover
                              v-else
                              class="size-form-bin"
                              icon-name="mdi-delete"
                              icon-hover="mdi-delete-empty"
                              small
                              @click="removeInputSize(index)" />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col :cols="6">
                        <v-btn
                          text
                          small
                          outlined
                          width="100%"
                          color="success"
                          @click="addSize()">
                          Add Size
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeFormModal()">
                    Cancel
                  </v-btn>
                  <v-btn
                    :loading="modalLoading"
                    color="blue darken-1"
                    type="submit"
                    text>
                    Save
                  </v-btn>
                </v-card-actions>
              </validation-observer>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item="{ item, headers }">
        <tr>
          <td class="d-flex">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-icon
                  v-if="item.bcSync && item.bcSync.sync && item.bcSync.bcLog"
                  v-bind="attrs"
                  color="success"
                  v-on="on">
                  mdi-check-circle-outline
                </v-icon>
                <v-icon
                  v-else
                  v-bind="attrs"
                  color="error"
                  v-on="on">
                  mdi-sync-alert
                </v-icon>
              </template>
              <span v-if="item.bcSync && item.bcSync.updatedAt">
                Last sync date {{ item.bcSync.updatedAt | dateTimeUserFormat() }}
              </span>
              <span v-else>
                Not sync yet
              </span>
            </v-tooltip>
          </td>
          <td
            class="text-start vertical-top pa-4"
            :width="headers[0].width">
            {{ item.type }}
          </td>
          <td class="text-start pa-4">
            <v-row
              class="chip-group"
              no-gutters>
              <v-chip
                v-for="(size, index) in item.size"
                :key="`chip-${size.type}-${index}`"
                x-small
                label
                class="ma-1">
                {{ size.name }}
              </v-chip>
            </v-row>
          </td>
          <td
            class="text-end vertical-top pa-4"
            :width="headers[2].width">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)">
              mdi-pencil
            </v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialogTable"
      persistent
      max-width="400"
      @click:outside="closeDialogTable()">
      <v-card>
        <v-card-title class="headline">
          Status
        </v-card-title>
        <v-simple-table>
          <thead>
            <tr>
              <th class="text-left">
                Type
              </th>
              <th class="text-left">
                Size
              </th>
              <th class="text-left">
                Action
              </th>
              <th class="text-center">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in apiStatus"
              :key="item.name">
              <td>{{ item.type }}</td>
              <td>{{ item.name }}</td>
              <td>
                <span class="caption text-capitalize">
                  {{ item.action }}
                </span>
              </td>
              <td>
                <v-btn
                  v-if="item.api || item.loading"
                  :color="btnColor(item.loading)"
                  :loading="item.loading"
                  small
                  text>
                  Success
                  <v-icon
                    right>
                    mdi-check
                  </v-icon>
                </v-btn>
                <v-tooltip
                  v-else
                  bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="error"
                      small
                      text
                      v-bind="attrs"
                      v-on="on">
                      Fail
                      <v-icon right>
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </template>
                  <span v-if="item.error">
                    {{ item.error.message }} ({{ item.error.code }})
                  </span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="sucess"
            text
            :disabled="disableModalTable"
            @click="closeDialogTable()">
            Done
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable no-await-in-loop */
import { mapActions } from 'vuex'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'

const ProductAttributeService = new ProductAttributeProvider()

export default {
  props: {
    attributeName: {
      type: String,
      requied: true,
      default: ''
    }
  },
  data () {
    return {
      dialogTable: false,
      dialog: false,
      loading: false,
      itemsLength: 0,
      pageCount: 0,
      options: {
        page: 1,
        itemsPerPage: 9999999,
        sortBy: ['id'],
        sortDesc: [false]
      },
      header: [
        {
          text: 'Sync',
          value: 'bcSync',
          width: 80
        },
        {
          text: 'Type',
          value: 'type',
          width: 100
        },
        {
          text: 'Sizes',
          value: 'size',
          width: 150
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: 60,
          align: 'end'
        }
      ],
      sizeData: [],
      isEdited: false,
      formData: {
        type: '',
        size: [{ id: null, name: '', action: 'create' }]
      },
      textFieldRules: [
        (v) => !!v || 'This field is required.'
      ],
      searchCombo: null,
      modalLoading: false,
      disableModalTable: false,
      apiStatus: []
    }
  },
  computed: {
    formTitle () {
      return this.isEdited ? 'Edit Item' : 'New Item'
    }
  },
  created () {
    this.getSize(this.options)
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setErrorPage: 'Components/setErrorPage',
      setModal: 'Components/setModal'
    }),
    btnColor (data) {
      return !data ? 'success' : ''
    },
    closeDialogTable () {
      if (!this.disableModalTable) {
        this.dialogTable = false
        this.getSize(this.options)
        this.closeFormModal()
      }
    },
    disableInputSize (action) {
      return action === 'delete'
    },
    capitalize (value) {
      if (!value) {
        return ''
      }
      return value.toString().charAt(0).toUpperCase() + value.slice(1)
    },
    async getSize (options) {
      try {
        this.loading = true
        const { data } = await ProductAttributeService.getProductAttribute('sizes', options)
        this.itemsLength = data.total
        this.pageCount = data.pages
        this.sizeData = this.mapSize(data.results)
      } catch (error) {
        console.error('getSize: ', error)
        this.setErrorPage(error.code)
      } finally {
        this.loading = false
      }
    },
    mapSize (data) {
      const sizeMapped = {}
      for (let i = 0; i < data.length; i++) {
        const size = data[i]
        if (!sizeMapped[size.type]) {
          sizeMapped[size.type] = []
        }
        sizeMapped[size.type].push({
          id: size.id,
          name: size.name,
          type: size.type,
          action: null
        })
      }
      return Object.keys(sizeMapped).map((key) => ({
        type: key,
        size: sizeMapped[key]
      }))
    },
    optionsUpdated () {
      this.getSize(this.options)
    },
    closeFormModal () {
      this.dialog = false
      this.isEdited = false
      this.modalLoading = false
      this.formData = {
        type: '',
        size: [{ name: '', action: 'create' }]
      }
    },
    editItem (item) {
      this.formData = JSON.parse(JSON.stringify(item))
      this.isEdited = true
      this.dialog = true
    },
    addSize () {
      this.formData.size.push({ id: null, name: '', action: 'create' })
    },
    removeInputSize (index) {
      if (this.isEdited && this.formData.size[index].id) {
        this.formData.size[index].action = 'delete'
      } else {
        this.formData.size.splice(index, 1)
      }
    },
    cancelRemoveInputSize (index) {
      this.inputSizeId(this.formData.size[index].id, index)
      this.inputSize(this.formData.size[index].name, index)
    },
    inputSizeId (val, index) {
      if (this.isEdited) {
        const typeEdit = this.sizeData.find((r) => r.type === this.formData.type)
        const isType = this.checkSizeDataIndex(typeEdit, index)
        if (isType && typeEdit.size[index].id === val) {
          this.formData.size[index].action = typeEdit.size[index].action
        } else if (isType && typeEdit.size[index].id !== val) {
          this.formData.size[index].action = 'update'
        } else {
          this.formData.size[index].action = 'create'
        }
      }
    },
    inputSize (val, index) {
      if (this.isEdited) {
        const typeEdit = this.sizeData.find((r) => r.type === this.formData.type)
        const isType = this.checkSizeDataIndex(typeEdit, index)
        if (isType && typeEdit.size[index].name === val) {
          this.formData.size[index].action = typeEdit.size[index].action
        } else if (isType && typeEdit.size[index].name !== val) {
          this.formData.size[index].action = 'update'
        } else {
          this.formData.size[index].action = 'create'
        }
      }
    },
    checkSizeDataIndex (data, index) {
      return !!data && !!data.size[index] && Array.isArray(data.size) && !!data.size[index].id && !!data.size[index].name
    },
    async save () {
      this.modalLoading = true
      const vaid = await this.$refs.sizeForm.validate()
      if (vaid) {
        const results = []
        for (let i = 0; i < this.formData.size.length; i++) {
          const size = {
            ...this.formData.size[i],
            type: this.formData.type,
            loading: true
          }
          if (size.action === 'create' || size.action === 'update' || (size.action === 'delete' && size.id)) {
            results.push(size)
          }
        }
        this.apiStatus = results
        if (this.apiStatus.length > 0) {
          this.dialogTable = true
          this.disableModalTable = true
          for (let i = 0; i < this.apiStatus.length; i++) {
            let size = this.apiStatus[i]
            if (size.action === 'create') {
              size = {
                ...size,
                ...await this.createSize(size)
              }
            } else if (size.action === 'update') {
              size = {
                ...size,
                ...await this.updateSize(size)
              }
            } else if (size.action === 'delete' && size.id) {
              size = {
                ...size,
                ...await this.removeSize(size)
              }
            }
            size.loading = false
            this.apiStatus.splice(i, 1, size)
          }
          this.disableModalTable = false
        } else {
          this.dialog = false
        }
      }
      this.modalLoading = false
    },
    async createSize (item) {
      let api = false
      let err = null
      try {
        const { data } = await ProductAttributeService.createProductAttribute('sizes', {
          id: item.id,
          type: item.type.trim(),
          name: item.name.trim()
        })
        api = !!data.id
      } catch (error) {
        console.error('createSize', error)
        err = error
      }
      return {
        ...item,
        api,
        error: err
      }
    },
    async updateSize (item) {
      let api = false
      let err = null
      try {
        const { data } = await ProductAttributeService.updateProductAttribute({
          path: 'sizes',
          id: item.id
        }, {
          type: item.type.trim(),
          name: item.name.trim()
        })
        api = !!data.id
      } catch (error) {
        console.error('updateSize', error)
        err = error
      }
      return {
        ...item,
        api,
        error: err
      }
    },
    async removeSize (item) {
      let api = false
      let err = null
      try {
        const { data } = await ProductAttributeService.deleteAttribute('sizes', item.id)
        api = !!data.id && data.status === 'deleted'
      } catch (error) {
        console.error('removeSize', error)
        err = error
      }
      return {
        ...item,
        api,
        error: err
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.vertical-top {
  vertical-align: top;
}
.col-input-size {
  position: relative;
}
button.size-form-bin {
  position: absolute;
  top: 30%;
  right: 12.5px;
}
</style>
